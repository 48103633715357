<template>
  <b-container class="mt-4">
    <b-row v-if="loading">
      <b-col>
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-row class="mb-4">
          <b-col class="d-flex align-items-center">
            <BackButton></BackButton>
            <h5 class="ml-2">Integrations for client
              <b-link :to="`/onboarding/client/edit/${client.id}`">{{ client.display_name }}</b-link>
            </h5>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button @click="saveIntegrations" variant="primary">
              <b-spinner small v-if="saving"></b-spinner>
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-card class="client-section mt-2 mb-3"
                v-for="ecosystem in ecosystems" :key="ecosystem.ecosystem_id"
                :header="ecosystem.label" header-tag="header">
          <template #header>
            <div class="d-flex justify-content-start align-items-center">
              <b-form-checkbox switch
                               :disabled="saving"
                               v-model="ecosystem.enabled"></b-form-checkbox>
              <h6 :class="['mb-0', ecosystem.enabled ? '' : 'text-muted']">{{ ecosystem.label }}</h6>
            </div>
          </template>
          <div class="row">
            <div class="col-4">
              <b-form-group label="Customer Key"
                            description="Customer key given by the provider">
                <b-form-input v-model="ecosystem.customerKey"
                              :disabled="saving"
                              type="text"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-8">
              <b-form-group label="Return to partner URL"
                            description="URL to return to partner on onboarding summary page">
                <b-form-input v-model="ecosystem.return_partner_url"
                              :disabled="saving"
                              type="text"></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-card>
        <b-card class="client-section mb-2" header="Bridge Health" header-tag="header">
          <template #header>
            <div class="d-flex justify-content-start align-items-center">
              <b-form-checkbox switch
                               :disabled="saving"
                               v-model="client.configuration.bridge_health"></b-form-checkbox>
              <h6 :class="['mb-0', client.configuration.bridge_health ? '' : 'text-muted']">Bridge Health</h6>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import Vue from 'vue';

import BackButton from '../../../components/BackButton.vue';

export default {
  name: 'ClientIntegrationsEdit',
  components: { BackButton },
  data() {
    return {
      client: {
        configuration: {
          bridge_health: false,
        },
      },
      ecosystems: [
        {
          ecosystem_id: 'highmark',
          enabled: false,
          label: 'HighMark',
        },
        {
          ecosystem_id: 'benefitsolver',
          enabled: false,
          label: 'Benefitsolver',
        },
        {
          ecosystem_id: 'solera',
          enabled: false,
          label: 'Solera',
        },
        {
          ecosystem_id: 'amwell',
          enabled: false,
          label: 'Amwell',
        },
      ],
      loading: false,
      saving: false,
    };
  },
  async beforeMount() {
    this.loading = true;
    await this.$store.dispatch('Core/fetchClients');
    const clients = this.$store.getters['Core/getClients'];
    this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
    await this.loadConfiguredEcosystems();

    this.loading = false;
  },
  methods: {
    ...mapActions('Integrations', [ 'requestEcosystemIntegrations', 'updateEcosystemConfig' ]),
    async loadConfiguredEcosystems() {
      const { data } = await this.requestEcosystemIntegrations({ clientId: this.client.id });
      if (!data || !data.ecosystems) {
        return;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const ecosystem of data.ecosystems) {
        const foundIndex = this.ecosystems.findIndex(e => e.ecosystem_id === ecosystem.ecosystem_id);
        if (foundIndex >= 0) {
          ecosystem.customerKey = ecosystem.customer_key;
          this.ecosystems[foundIndex] = Object.assign(this.ecosystems[foundIndex], ecosystem);
        }
      }
    },
    getEcosystemData(ecosystemId) {
      return this.ecosystems.find(e => e.ecosystem_id === ecosystemId);
    },
    async saveIntegrations() {
      this.saving = true;
      try {
        await this.saveEcosystems();
        await this.updateClient();
      } catch (e) {
        Vue.$noty.error('Unable to save integrations', e);
      } finally {
        this.saving = false;
      }
    },
    async saveEcosystems() {
      const savePromises = this.ecosystems.map(ecosystem => this.updateEcosystemConfig({
        clientId: this.client.id,
        ecosystem: ecosystem.ecosystem_id,
        enabled: ecosystem.enabled,
        customerKey: ecosystem.customerKey,
        returnPartnerURL: ecosystem.return_partner_url,
        clientReference: this.client.reference,
        institutionId: this.client.institution_id,
      }));

      return Promise.all(savePromises);
    },
    async updateClient() {
      const formData = new FormData();
      Object.entries(this.client).forEach(([ key, value ]) => {
        formData.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
      });

      return axios.put(`v1/clients/${this.client.id}`, formData);
    },
  },
};
</script>
